import React from "react";
import { withNavBars } from "../../HOCs";
import NewsList from "./newsList";
class newsListParent extends React.Component {
  render() {
    return <NewsList />;
  }
}

export default withNavBars(newsListParent);
