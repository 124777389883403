import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";
import PrivateRoute from "./privateRouter";

import {
  NotFound,
  DashboardParent,
  Login,
  ChangePassword,
  Settings,
  Demo,
  Event,
  EventMembers,
  Pricing,
  EveryOneBecomePartner,
  LandingPageUpload,
  AboutUsIconUpload,
  CareersUpload,
  PartnerShip,
  Blogs,
} from "./../screens";
import CreateBlog from "../screens/createBlog";
import CreateNews from "../screens/createNews";
import AuthorList from "../screens/test";
import AddAuthors from "../screens/addAuthors";
import AddCategorys from "../screens/categoryList";
import CategoryImage from "../screens/categoryImage";
import NewsList from "../screens/newsTable";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Login Route */}
        <Route exact path={Routes.login}>
          <Login />
        </Route>

        {/* Change Password */}
        <PrivateRoute exact path={Routes.changePassword}>
          <ChangePassword />
        </PrivateRoute>

        {/* DashboardParent Route */}
        <PrivateRoute exact path={Routes.dashboard}>
          <DashboardParent />
        </PrivateRoute>

        {/*settings Routes */}
        <PrivateRoute exact path={Routes.settings}>
          <Settings />
        </PrivateRoute>

        {/* Request Routes*/}
        <PrivateRoute exact path={Routes.demo}>
          <Demo />
        </PrivateRoute>

        {/* partnership Routes*/}
        <PrivateRoute exact path={Routes.partnership}>
          <PartnerShip />
        </PrivateRoute>

        {/* Pricing Routes*/}
        <PrivateRoute exact path={Routes.pricing}>
          <Pricing />
        </PrivateRoute>

        {/* EveryoneBecome partner Routes*/}
        <PrivateRoute exact path={Routes.everyOneBecomePartner}>
          <EveryOneBecomePartner />
        </PrivateRoute>

        {/* Event Members Routes*/}
        <PrivateRoute exact path={Routes.event_members}>
          <EventMembers />
        </PrivateRoute>

        {/* Careers Upload Routes*/}
        <PrivateRoute exact path={Routes.careersUpload}>
          <CareersUpload />
        </PrivateRoute>

        {/* landingPageUpload */}
        <PrivateRoute exact path={Routes.landingPageUpload}>
          <LandingPageUpload />
        </PrivateRoute>
        {/* aboutUsUpload */}
        <PrivateRoute exact path={Routes.aboutUsUpload}>
          <AboutUsIconUpload />
        </PrivateRoute>

        {/* Event Routes*/}
        <PrivateRoute exact path={Routes.event}>
          <Event />
        </PrivateRoute>

        {/* Event Routes*/}
        <PrivateRoute exact path={Routes.blogs}>
          <Blogs />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.createBlog}>
          <CreateBlog />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.authorList}>
          <AuthorList />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.newsList}>
          <NewsList />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.addAuthors}>
          <AddAuthors />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.addCategory}>
          <AddCategorys />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.categoryImage}>
          <CategoryImage />
        </PrivateRoute>
        <PrivateRoute exact path={Routes.createNews}>
          <CreateNews />
        </PrivateRoute>

        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
