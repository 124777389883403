import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { AlertDialog, TableComponent, TopNavBar } from "../../components";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { EventUseStyles } from "./style";
import { config } from "../../config";
import { EventReqJson } from "./tableData";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AlertContext } from "../../contexts";

export const NewsList = (props) => {
  const { t } = useTranslation(["topBar"]);
  const classes = EventUseStyles();
  const history = useHistory();
  const [blogList, setBlogList] = React.useState([]);
  const [popup, setPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const alert = React.useContext(AlertContext);

  React.useEffect(() => {
    getBlogList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBlogList = () => {
    let params = {};
    NetworkCall(
      `${config.api_feature}api/v1/news/get_news `,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        let response = res?.data?.news;
        setBlogList(
          response?.map((item, index) => {
            return {
              sl: index + 1,
              id: item?.id,
              authorName: item?.title ? item?.title : "-",
              status: item?.is_draft
                ? "Draft"
                : item?.is_active
                ? "Published"
                : "Inactive",
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDelete = (row, params, event) => {
    setSelectedRow(row);
    setPopup(true);
  };

  const handleDelete = (row) => {
    let params = {
      id: row?.id,
      status:
        row?.status === "Published" || row?.status === "Draft"
          ? "Inactive"
          : "Active",
    };
    NetworkCall(
      `${config.api_feature}api/v1/news/delete_news  `,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((res) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: res?.data?.message,
        });
        setPopup(false);
        getBlogList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const popupClose = () => {
    setPopup(!popup);
  };

  const onEdit = (props) => {
    const state = {
      id: props.id,
      edit: "edit",
    };
    history.push({
      pathname: "/createNews",
      state: { ...state }, // Pass the state object to the page
    });
  };

  return (
    <>
      <TopNavBar
        title={t("Provision to Add and Edit News")}
        buttonLabel={false}
      />
      <div className={classes.root}>
        {/* <ExcelFile style={{ display: "flex" }} element={<Typography className={classes.btn}>Download CSV</Typography>}>
                    <ExcelSheet dataSet={excelJson} name="fdskj" />
                </ExcelFile> */}
        <Box className={classes.btnBox}>
          <Stack direction="row" className={classes.btnGrp}>
            <Typography
              className={classes.btn}
              onClick={() => history.push(Routes.createNews)}
            >
              Add News
            </Typography>
            {/* <Typography className={classes.btn} ml={2}>
              Filter
            </Typography> */}
          </Stack>
        </Box>

        <TableComponent
          dataList={blogList}
          Header={EventReqJson(onEdit, onDelete)}
          isChecked
        />
        <AlertDialog
          open={popup}
          onClose={() => popupClose()}
          header={"Delete Blog"}
          // "Add Event"
          component={
            <Box className={classes.alertBox}>
              <Typography>Are You Sure You Want Delete?</Typography>
              <Stack my={2} spacing={2} direction={"row"} alignItems={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => handleDelete(selectedRow)}
                >
                  Yes
                </Button>
                <Button variant="outlined" onClick={() => popupClose()}>
                  No
                </Button>
              </Stack>
            </Box>
          }
          isNormal
        />
      </div>
    </>
  );
};

export default NewsList;
