import React from "react";
import { withNavBars } from "../../HOCs";
import CreateNews from "./createNews";

class CreateNewsParent extends React.Component {
  render() {
    return <CreateNews />;
  }
}

export default withNavBars(CreateNewsParent);
