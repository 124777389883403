import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const EventReqJson = (onEdit, onDelete) => {
  return {
    TablecolumnData: [
      { field: "sl", headerName: "S.No", width: 80 },
      { field: "authorName", headerName: "News", width: 500 },
      { field: "status", headerName: "Status", width: 150 },
      {
        field: "edit",
        headerName: "Edit",
        width: 120,
        renderCell: (params) => {
          return (
            <IconButton onClick={(e) => onEdit(params.row, params, e)}>
              <EditIcon />
            </IconButton>
          );
        },
      },
      {
        field: "delete",
        headerName: "Delete",
        width: 120,
        renderCell: (params) => {
          return (
            <IconButton
              onClick={(e) => {
                onDelete(params.row, params, e);
              }}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ],
    TableData: [],
  };
};
